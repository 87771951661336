import $ from 'jquery';
import _ from 'lodash';
import { settings } from '../../../areas/main/config';
import { initializeGridDropDown } from "../grid/initializeGrid";
import { GanttModel } from '../binding-handlers/gantt';

export class Gantt {
	readonly rowHeight = 34;

	gantt: kendo.ui.Gantt;
	header: JQuery;
	footer: JQuery;
	gridHeader: JQuery;

	constructor(public element: JQuery, public model?: GanttModel, public minHeight?: number) {}

	async initAsync(options: kendo.ui.GanttOptions): Promise<kendo.ui.Gantt> {
		await import(/* webpackChunkName: "kendo-scheduler" */ '../../../plugins/kendo/scheduler');

		const autoBind = options.autoBind || true;
		options.autoBind = false;

		if (!options.tooltip) {
			options.tooltip = {
				visible: true,
				template: kendo.template(
					`<div class="k-task-details">
						<strong>#: task.title #</strong>
						#if (task.percentComplete !== null && task.percentComplete !== undefined) {#
							<div class="k-task-pct">#: kendo.toString(task.percentComplete, 'P') #</div>
						#}#
						#if (task.statusText) {#
							<div class="task-status">
								#if (task.statusColor) {#
									<span class="task-status-color #: task.statusColor #"></span>
								#}#
								#: task.statusText #
							</div>
						#}#
						<ul class="k-reset">
							<li>${settings.strings.start}: #: kendo.toString(task.start, 'G') #</li>
							<li>${settings.strings.end}: #: kendo.toString(task.end, 'G') #</li>
						</ul>
					</div>`
				)
			}
		}

		this.gantt = this.element.kendoGantt({
			...{
				views: [
					{
						type: 'day',
						dayHeaderTemplate: kendo.template('#=kendo.toString(start, "D")#'),
						timeHeaderTemplate: kendo.template(`#=kendo.toString(start, "${this.model.hoursPattern}")#`),
						slotSize: 40
					},
					{
						type: 'week',
						dayHeaderTemplate: kendo.template('#=kendo.toString(start, "ddd").charAt(0)#'),
						weekHeaderTemplate: kendo.template('#=kendo.toString(start, "d")#'),
						slotSize: 30,
						selected: true
					},
					{
						type: 'month',
						monthHeaderTemplate: kendo.template('#=kendo.toString(start, "Y")#'),
						weekHeaderTemplate: kendo.template('#=kendo.toString(start, "d")#'),
						slotSize: 120
					}
				],
				height: this.rowHeight * 4,
				rowHeight: this.rowHeight,
				resizable: true,
				selectable: false,
				showWorkHours: false,
				showWorkDays: false,
				snap: false,
				editable: false,
				dataBound: () => this.dataBound(),
				taskTemplate: kendo.template(
					`<div class="task-details #= data.statusColor || 'background-default' #">
						<div class="task-text">
							<strong class="title" data-id="#= title #">#= title #</strong>
						</div>
						# if (percentComplete) { #
							<div class="task-progress" style="width:#= (100 * parseFloat(percentComplete)) #%"> </div>
						# } #
					</div>`
				)
			},
			...options
		}).data('kendoGantt');

		this.header = this.element.find('.k-gantt-header');
		this.footer = this.element.find('.k-gantt-footer');
		const treelist = this.element.find('.k-gantt-treelist');
		this.gridHeader = treelist.find('.k-grid-header');

		this.element.find('.k-grid-content')
			.on('scroll', _ => {
				treelist
					.find('[data-toggle="dropdown"][aria-expanded="true"]')
					.dropdown('toggle');
			});

		initializeGridDropDown(this.element);

		if (autoBind) {
			this.gantt.dataSource.read();
		}

		if (this.model.canAdd) {
			this.element
				.find('.k-gantt-toolbar button.k-gantt-create .k-button-text')
				.text(settings.strings.addItem);
		}

		return this.gantt;
	}

	dataBound(): void {
		this.syncCssClasses();
		this.resize();
	}

	resize(): void {
		const headerHeight = this.header.outerHeight() + (this.footer?.outerHeight() ?? 0)  + this.gridHeader.outerHeight();
		const minHeight = this.minHeight ?? (headerHeight + this.rowHeight * 2);

		var max = $(window).height() - this.gantt.element.offset().top - 10;
		if (max < minHeight) {
			max = minHeight;
		}

		//we add 21 to prevent vertical scroll from always shown
		var height = headerHeight + (this.rowHeight * this.gantt.dataSource.total()) + 21;
		if (height > max) {
			height = max;
		}

		this.gantt.wrapper.height(height);
		this.gantt.resize(true);
	}

	//This is a workaround since kendo currently doesn't support null start/end dates. If one or
	//more tasks are missing a start date then even valid tasks are not displayed on the timeline.
	//Valid tasks will display as long as all tasks have at least a start date, and tasks with only a
	//start date are not displayed on the timeline.
	//Feature Request: https://feedback.telerik.com/kendo-jquery-ui/1359454-add-support-for-tasks-without-dates-in-gantt-chart
	ensureDates(tasks: kendo.data.GanttTask[]): void {
		let minStart: Date;
		const invalidTasks = [];
		
		for (let task of tasks) {
			//fall back to the end date if start date is missing
			task.start = task.start || task.end;
		
			//fall back to the start date if end date is missing
			task.end = task.end || task.start;
		
			if (!task.start) {
				invalidTasks.push(task);
			}
			else if (!minStart || minStart > task.start) {
				minStart = task.start;
			}
		}
		
		//set anything task without a start date to use the min start date
		//to ensure the timeline is displayed for all other valid tasks
		invalidTasks.forEach(x=> x.start = minStart);
	}

	syncCssClass(task: kendo.data.GanttTask) {
		const row = $(`.k-gantt-tasks .k-task[data-uid='${task.uid}'] .k-task-content`);
		if (row.length === 0) {
			return;
		}

		if (!row.attr('data-default-class')) {
			row.attr('data-default-class', row.attr('class'))
		}

		row
			.attr('class', row.attr('data-default-class'))
			.addClass(task.cssClass);
	}

	syncCssClasses(): void {
		const tasks = this.gantt.dataSource.data();
		for (let n = 0; n < tasks.length; n++) {
			this.syncCssClass(tasks[n]);
		}
	}
}
